
import { defineComponent } from "vue";
import CreateEditDialog from "@/components/controls/catalogueGrid/CreateEditDialog.vue";
import mountComponentMixin from "@/mixins/MountComponentMixin";
import TextControl from "@/components/controls/base/TextControl.vue";

export default defineComponent({
  components: {
    CreateEditDialog,
    TextControl,
  },
  mixins: [mountComponentMixin],
  props: {
    saveModelCallback: { required: true },
  },
  computed: {},
  methods: {
    dialog() {
      return this.$refs.dialog as any;
    },
    open(model: any, editType: any) {
      //ref
      this.dialog().open(model, editType);
    },
    close() {
      //ref
      this.dialog().close();
    },
    save() {
      //ref
      this.dialog().save();
    },

    onShown(model: any) {
      (this as any).mountComponent(
        "components/catalogues/RoleCatalogue/RolePolicySubGrid.vue",
        (this as any).policySubGridDomId,
        {
          parentModel: model,
        }
      );
    },
    beforeUnmount() {
      (this as any).unmountComponent((this as any).policySubGridDomId);
    },
  },
  data() {
    return {
      policySubGridDomId: "RoleCreateEditDialog_policySubCatalogueGrid",
    };
  },
});
